.footer{
    .foo-top{
        .prepone-logo{
            width: 100px;
            height: 100px;
            
            img{
                width: 100%;
            }
        }
    }
}