.abt__sect__payment{
    background-color: white !important;
    border: 1px solid red;

    .container{
        display: none !important;
    }
}

.abt__payment__sect{
    padding: 10px 40px;
    margin: 90px 450px;
    background-color: red;
}