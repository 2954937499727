@import "./utilities.scss";
@import "../components/global/navbar/navbar";
@import "../components/testimonials/testimonial.scss";
@import "../components/RechargeForm/rechargeform.scss";
@import "../components/AboutPage/about.scss";
@import "../components/Banner/banner.scss";
@import "../components/global/footer/footer.scss";
@import "../components/RechargeComps/recharge-comps.scss";
@import "../components/Alert/Spinner/spinner";
@import "../components/profileComps/profile-comp.scss";
// @import "../components/global/navbar/MobileNav/mobilenav.scss";
// @import "../components/global/navbar/NavHeader/navheader.scss";


*{
  box-sizing: border-box;
  padding: 0;
  margin: 0; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700|Roboto:300,400,500,700&display=swap");
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

body {
  color: #83868c;
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 27px;
}

a {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

a:hover, a:focus {
  text-decoration: none;
  outline: 0px;
}

h1, h2, h3, h4, h5, h6 {
  color: #0c2e60;
  font-weight: 600;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

figure {
  margin: 0;
}

input:active, input:focus {
  outline: 0;
}

section {
  display: block;
  position: relative;
  padding: 50px 0 60px;
  width: 100%;
}

/**
 *
 * Common CSS
 *
 */
body {
  display: block;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}




.portGrid {
  display: flex;
  justify-content: center; 
  flex-wrap: wrap; 
}

.protItem {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  justify-content: center; 
  text-align: center; 
  padding: 10px; 
  transition: transform 0.3s ease; 
}

.protItem:hover {
  transform: scale(0.9); 
}

.protItem img {
  max-width: 100%; 
  height: auto; 
}


