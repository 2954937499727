$prepone-black: black;
$prepone-yellow: #ffdd40;

$prepone-dark-blue: #1f2d42;
$prepone-light-blue: #007bff;
$prepone-blue: #154abd;
$prepone-white: #fff;

$blue-violet: #9417E2;
$purple: #6C0BA9;


:root{
  // --purple: #3f0d5e;
  --purple: #6C0BA9;
  --blue-violet: #9417E2;
  ---medium-orchid: #BC61F5;
}


/* Animations */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}
  
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateX(0);
  }
}


@keyframes slideAwayFromLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }

  
}

  