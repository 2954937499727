.testimonial{
  

    .container{
        .row{
            

            .testimonialmainBox{
               
                .testimonialBox{
                   
                   .test-caro{
                       
                       .caro-stage-outer{
                           display: flex !important;
                           width: 100% !important;
                           .caro-stage{

                            
                               margin: 0 auto !important;
                               display: flex !important;
                            //    flex-direction: row;
                               align-items: center;
                               justify-content: space-between !important;
                               width: 100% !important;

                               .caro-item{
                                //    width: 100%;

                                   .single-testimonial{
                                       width: 300px;
                                       padding: 10px;
                                       margin-top: 20px;
                                       padding-top: 70px;

                                       position: relative;

                                       .testimonial-user{
                                           width: 100px;
                                           height: 100px;
                                           border-radius: 50%;

                                           position: absolute;

                                           img{
                                               width: 100%;
                                           }
                                       }
                                   }
                               }
                           }
                       }
                   }
                }
            }
        }
    }
}








@media (max-width: 375px) {
    .testimonial{
        
    
        .container{
            display: flex;
            flex-direction: column;
            
            .row{
                margin: 0 auto !important;
                display: flex !important;
                .testimonialmainBox{
                    .testimonialBox{
                       .test-caro{
                           .caro-stage-outer{
                            display: flex !important;
                            width: 100% !important;

                               .caro-stage{
                                   display: flex;
                                   flex-direction: column;
                                   
                                   
                                   width: 100% !important;
                                   
    
                                   .caro-item{
                                    margin: 0 auto !important;
                                    margin-right: 20px;
                                    width:100% !important;
                                    
                                       .single-testimonial{
                                           width:100%;
                                           padding: 10px;
                                           margin-top: 20px;
                                           padding-top: 70px;
    
                                           position: relative;
    
                                           .testimonial-user{
                                               width: 100px;
                                               height: 100px;
                                               border-radius: 50%;
    
                                               position: absolute;
    
                                               img{
                                                   width: 100%;
                                               }
                                           }
                                       }
                                   }
                               }
                           }
                       }
                    }
                }
            }
        }
    }
}