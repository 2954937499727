.nav__container {
  height: 60px;
  display: flex;
  // justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 20;

  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  // margin-bottom: 50px;

  .nav-icon {
    display: none;
  }

  .main__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 82.2%;
    max-width: 1500px;
    margin: 0 auto;

    .logo {
      display: flex;
      width: 85px;
      a {
        color: $prepone-blue;
        font-size: 1.5rem;
        cursor: pointer;
        margin: auto;
      }
      img {
        width: 100%;
      }
    }

    .nav-menu {
      display: flex;
      align-items: center;
      text-align: center;
      background-color: white;
      z-index: 20;

      .nav-item {
        color: var(--purple);
        font-size: 1rem;
        font-weight: 500;

        .nav-link {
          color: var(--purple);
          height: 100%;
          // border: 3px solid transparent;

          &:hover {
            color: var(--purple);
            // border: 2px solid #9417E2;
          }
        }

        :after {
          content: "";
          display: block;
          height: 3px;
          width: 0;
          background: transparent;
          transition: width 0.7s ease, background-color 0.5s ease;
        }

        :hover:after {
          width: 100%;
          background-color: var(--purple);
          color: var(--purple);
        }

        .dropdown {
          height: 55px;

          padding-top: 5px;

          button {
            color: var(--purple);
            font-size: 1rem;
            line-height: 1.2;
            text-transform: capitalize;
            background: transparent;
            height: 30px;
            padding-right: 0;
            padding-left: 0;
            display: flex;
            align-items: center;

            &:hover {
              // color: var(--purple);
            }
          }

          .dropdown-menu {
            border: none;

            .dropdown-item {
              color: var(--purple) !important;
              font-size: 1rem;
              font-weight: 500;

              &:hover {
                cursor: pointer;
                color: white !important ;
                // background-color: #9417E2 !important ;
                // padding: 5px 10px;
              }

              a {
                color: var(--purple);

                &:hover {
                  cursor: pointer;
                  // color: white !important ;
                  // background-color: #9417E2 !important ;
                  padding: 5px 10px;
                }
              }
              // &:focus{
              //     background-color: red !important ;
              // }
              &:active {
                background-color: #9417e2 !important ;
                color: white !important ;
              }
            }
          }

          :hover {
            box-shadow: none;
          }
        }
      }

      .active {
        color: var(--purple);

        .nav-link {
          color: rgb(233, 11, 233);
          height: 100%;
        }
      }

      .mobile-logo-container {
        display: none;
      }

      .only-for-mobile {
        display: none;
      }
    }
  }
}

.mobile-menu {
  display: none;
}

@media screen and (max-width: 414px) {
  .nav__container {
    background-color: white;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 0;
    position: sticky !important;
    top: 0;

    z-index: 99;
    width: 100%;
    // height: 100vh;

    opacity: 1;
    transition: all 0.5s ease;

    .nav-icon {
      display: block;
      position: absolute;
      top: 20px;
      left: 340px;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: $prepone-blue;
      width: 20px;
      height: 0;
      z-index: 99;

      img {
        width: 100%;
      }
    }

    .main__navbar {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      max-width: 200px;

      position: relative;

      .logo {
        display: flex;
        position: absolute;
        top: 0;
        width: 70px;
        // left: 230px;
        right: 190px;
        // z-index: 999;
        // background-color: #fff;
        // border-radius: 50%;

        a {
          font-size: 1.5rem;
          cursor: pointer;
          margin: auto;
        }
      }

      .nav-menu {
        // background-color: white;
        // background-color: #13051d;
        background-color: #07010b;
        // background-color: $prepone-dark-blue;

        height: 100vh;
        position: fixed !important;
        top: 0;
        left: 0;
        z-index: 200;
        transform: translateX(-100%);
        transition: all 0.3s ease-out;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: start;
        padding-top: 120px;
        width: 250px;

        .nav-item {
          width: 100%;

          padding-left: 30px;

          .nav-link {
            // color: $prepone-black;
            color: white;
            font-size: 1.2rem;
            font-weight: 500;
            height: 100%;
            border-bottom: 1px solid transparent;
            padding-left: 0;
            margin-bottom: 20px;
          }

          :after {
            content: "";
            display: block;
            height: 3px;
            width: 0;
            background: transparent;
            transition: width 0.7s ease, background-color 0.5s ease;
          }

          :hover:after {
            width: 100%;
            background: #9417e2 !important ;
          }

          .dropdown {
            height: 55px;

            padding-left: 0;

            button {
              // color: $prepone-black;
              color: white;
              font-size: 1.2rem;
              line-height: 1.2;
              text-transform: capitalize;
              background: transparent;
              height: 30px;
              padding-right: 0;
              padding-left: 0;
              display: flex;
              align-items: center;

              &:hover {
                color: #9417e2 !important ;
              }
            }

            .dropdown-menu {
              border: none;

              .dropdown-item {
                color: $prepone-black;
                font-size: 1rem;
                font-weight: 500;
              }
            }

            :hover {
              box-shadow: none;
            }
          }
        }
        .active {
          color: #6c0ba9;

          .nav-link {
            color: #6c0ba9;
            height: 100%;
            border-bottom: 1px solid transparent;
          }
        }

        .mobile-logo-container {
          display: flex;
          height: 60px;
          width: 60px;
          background-color: white;
          border-radius: 50%;

          position: absolute;
          top: 20px;
          left: 30px;

          .mobile-logo {
            width: 100px;
            height: 100px;
            margin: auto;

            img {
              width: 100%;
            }
          }
        }

        .only-for-mobile {
          display: block;
        }
      }

      .show {
        transform: translateX(0);
      }

      .hide {
        // transition: 2s ease-out;
        // left: -110%;
        // z-index: 10;
        // animation: slideAwayFromLeft 1s ease-in;
      }
    }
  }
}
