.prepone-select{
    display: block;
    font-size: 20px;
    font-weight: 400;
    text-transform: capitalize;

    .prepone-option{
        &:hover{
            background-color: $prepone-blue !important;
            padding: 20px 0;
        }
    }
}




@media screen and (max-width: 414px) {
    .profile-content{
        .operators{

            .operator-row{
                display: flex;
                flex-direction: row;
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                padding: 0 20px;
                gap: 0;

                .single-operator{
                    .op-logo{
                        width: 60px;
                        height:60px;

                        img{
                            width: 100%;
                        }
                    }


                    .last-one{
                        display: none;
                    }
                }
            }
    
        }
    }
}
