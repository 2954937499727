.main-form{
    .container{
        .for-mobile{
            display: none;
        }
        .row{
            .col-md-9{
                .featured-offer{
                    .featured-image-container{
                        .featuredOffer-caro{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .featuredItems{
                                img{
                                    width: 100%;
                                    height: 100%;
                                    
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}





@media (max-width: 375px) {
    .main-form{
        .container{
            .row{
                .col-md-9{
                    .featured-offer{
                        .featured-image-container{
                            .featuredOffer-caro{
                                display: flex;
                                flex-direction: column;
                                // align-items: center;
                                // background-color: red;
                                // justify-content: space-between;
                                .featuredItems{
                                    width: 300px;
                                    margin-bottom: 10px;
                                    img{
                                        width: 100%;
                                        height: 100%;
                                        
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}




@media screen and (max-width: 414px) {

    .main-form{
        .container{
            width: 100%;
            display: flex;
            flex-direction: column;
            
            .for-mobile{
                display: flex;
                width: 100%;
                margin: auto;

                .for-mobile-ul{
                    display: flex;
                }
            }
            .not-for-mobile{
                display: none;
            }

            .morebtn{
                .dropdown{
                    background-color: #6C0BA9;
                    height: 100%;
                    color: white !important;
                    display: flex;
                    flex-direction: column;
                    i{
                        margin: 0 auto;
                        margin-top: 5px;
                    }

                    button{
                        color: white !important;
                        padding-top: 10px;
                        padding-right: 0;
                        padding-left: 0;
                        width: 80px;
                        margin: 0 auto;
                    }

                    .dropdown-menu{
                        background-color: #6C0BA9;
                        margin-top: 135px !important;
                        .dropdown-item{
                            width: 100%;
                            color: white;

                            &:hover {
                                color: #6C0BA9;
                            }
                        }
                    }
                }
            }


            .row{
                margin-top: 40px;
            }
        }
    }
}